import React, { useState, useEffect } from 'react';
import { ArrowLeft, Calendar, Clock, Filter, Home, MapPin, MessageCircle, Plus, User, X, Check, Edit2, Hash, Camera, Trash2, Bell } from 'lucide-react';

function App() {
  const [currentScreen, setCurrentScreen] = useState('Home')
  const [activities, setActivities] = useState([
    { id: 1, title: 'Morning Run', location: 'Marina', date: '2023-06-15', time: '07:00 AM', miles: 3.1, description: 'Join us for a refreshing morning run. All levels welcome!', creator: 'John Doe' },
    { id: 2, title: 'Trail Run', location: 'Noe Valley', date: '2023-06-17', time: '08:00 AM', miles: 6.2, description: 'Challenging trail run with beautiful scenery. Intermediate to advanced runners.', creator: 'Jane Smith' },
    { id: 3, title: 'Beginner Jog', location: 'Marina', date: '2023-06-18', time: '09:00 AM', miles: 2, description: 'Easy-paced jog for beginners. Great for those just starting their running journey!', creator: 'John Doe' },
  ])
  const [filteredActivities, setFilteredActivities] = useState(activities)
  const [selectedActivity, setSelectedActivity] = useState(null)
  const [showFilters, setShowFilters] = useState(false)
  const [filters, setFilters] = useState({
    location: '',
    miles: '',
    time: '',
    date: ''
  })
  const [rsvpConfirmed, setRsvpConfirmed] = useState({})
  const [profile, setProfile] = useState({
    name: 'John Doe',
    location: 'Marina',
    preferredDistance: '5K - 10K',
    avgPace: '8:30',
    favoriteTerrain: 'Trail',
    image: null
  })
  const [editingProfile, setEditingProfile] = useState(false)
  const [comments, setComments] = useState({})
  const [activeTab, setActiveTab] = useState('Upcoming')
  const [notifications, setNotifications] = useState([
    { id: 1, message: 'New activity near you!', read: false },
    { id: 2, message: 'John Doe commented on your activity', read: false },
  ])

  const neighborhoods = ['Marina', 'Noe Valley', 'Mission', 'SoMa', 'North Beach']

  const handleFilterChange = (e) => {
    const { name, value } = e.target
    setFilters(prev => ({ ...prev, [name]: value }))
  }

  const handleRSVP = (activityId) => {
    setRsvpConfirmed(prev => ({ ...prev, [activityId]: !prev[activityId] }))
  }

  const handleProfileChange = (e) => {
    const { name, value } = e.target
    setProfile(prev => ({ ...prev, [name]: value }))
  }

  const handleSaveProfile = () => {
    setEditingProfile(false)
  }

  const handleCreateActivity = (newActivity) => {
    const id = activities.length + 1
    const activityWithCreator = { ...newActivity, id, creator: profile.name }
    setActivities(prev => [...prev, activityWithCreator])
  }

  const handleImageUpload = (e) => {
    const file = e.target.files[0]
    if (file) {
      const reader = new FileReader()
      reader.onloadend = () => {
        setProfile(prev => ({ ...prev, image: reader.result }))
      }
      reader.readAsDataURL(file)
    }
  }

  const handleEditActivity = (activityId, updatedActivity) => {
    setActivities(prev => prev.map(activity => 
      activity.id === activityId ? { ...activity, ...updatedActivity } : activity
    ))
  }

  const handleDeleteActivity = (activityId) => {
    setActivities(prev => prev.filter(activity => activity.id !== activityId))
  }

  const handleAddComment = (activityId, comment) => {
    setComments(prev => ({
      ...prev,
      [activityId]: [...(prev[activityId] || []), { id: Date.now(), text: comment, user: profile.name }]
    }))
  }

  const handleDeleteComment = (activityId, commentId) => {
    setComments(prev => ({
      ...prev,
      [activityId]: prev[activityId].filter(comment => comment.id !== commentId)
    }))
  }

  const handleNotificationClick = (id) => {
    setNotifications(notifications.map(notif => 
      notif.id === id ? { ...notif, read: true } : notif
    ))
    // Add logic to navigate to the relevant screen based on notification type
  }

  useEffect(() => {
    const applyFilters = () => {
      const weighted = activities.map(activity => {
        let score = 0
        if (activity.location === filters.location) score += 4
        if (activity.date === filters.date) score += 3
        if (Math.abs(activity.miles - parseFloat(filters.miles)) <= 1) score += 2
        if (activity.time === filters.time) score += 1
        return { ...activity, score }
      })

      const sorted = weighted.sort((a, b) => b.score - a.score)
      setFilteredActivities(sorted)
    }

    applyFilters()
  }, [filters, activities])

  const renderScreen = () => {
    switch (currentScreen) {
      case 'Home':
        return (
          <>
            <div className="flex items-center justify-between p-4 bg-white shadow">
              <h1 className="text-2xl font-bold text-indigo-600">Novara</h1>
              <div className="flex items-center">
                <button onClick={() => setCurrentScreen('Notifications')} className="mr-2 relative">
                  <Bell className="h-6 w-6 text-indigo-500" />
                  {notifications.some(n => !n.read) && (
                    <span className="absolute top-0 right-0 block h-2 w-2 rounded-full bg-red-500"></span>
                  )}
                </button>
                <button onClick={() => setShowFilters(true)} className="p-2 rounded-lg bg-indigo-500 text-white">
                  <Filter className="h-5 w-5" />
                </button>
              </div>
            </div>
            {showFilters && (
              <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center">
                <div className="bg-white p-4 rounded-lg w-11/12 max-w-md">
                  <div className="flex justify-between items-center mb-4">
                    <h2 className="text-lg font-semibold text-indigo-600">Filters</h2>
                    <button onClick={() => setShowFilters(false)}>
                      <X className="h-6 w-6 text-gray-500" />
                    </button>
                  </div>
                  <div className="flex items-center mb-4">
                    <MapPin className="h-5 w-5 text-indigo-500 mr-2" />
                    <select
                      name="location"
                      value={filters.location}
                      onChange={handleFilterChange}
                      className="w-full p-2 bg-gray-100 rounded-lg text-gray-700"
                    >
                      <option value="">Select Neighborhood</option>
                      {neighborhoods.map(neighborhood => (
                        <option key={neighborhood} value={neighborhood}>{neighborhood}</option>
                      ))}
                    </select>
                  </div>
                  <div className="flex items-center mb-4">
                    <Calendar className="h-5 w-5 text-indigo-500 mr-2" />
                    <input
                      type="date"
                      name="date"
                      value={filters.date}
                      onChange={handleFilterChange}
                      className="w-full p-2 bg-gray-100 rounded-lg text-gray-700"
                    />
                  </div>
                  <div className="flex items-center mb-4">
                    <Hash className="h-5 w-5 text-indigo-500 mr-2" />
                    <input
                      type="number"
                      name="miles"
                      value={filters.miles}
                      onChange={handleFilterChange}
                      placeholder="Miles"
                      className="w-full p-2 bg-gray-100 rounded-lg text-gray-700"
                    />
                  </div>
                  <div className="flex items-center mb-4">
                    <Clock className="h-5 w-5 text-indigo-500 mr-2" />
                    <input
                      type="time"
                      name="time"
                      value={filters.time}
                      onChange={handleFilterChange}
                      className="w-full p-2 bg-gray-100 rounded-lg text-gray-700"
                    />
                  </div>
                  <button
                    onClick={() => setShowFilters(false)}
                    className="w-full bg-indigo-500 text-white rounded-lg py-2 hover:bg-indigo-600 transition-colors"
                  >
                    Apply Filters
                  </button>
                </div>
              </div>
            )}
            <div className="flex-1 overflow-auto p-4">
              {filteredActivities.map((activity) => (
                <div key={activity.id} className="bg-white rounded-lg shadow mb-4 p-3 hover:shadow-md transition-shadow">
                  <div className="flex items-center justify-between mb-1">
                    <h2 className="text-lg font-semibold text-indigo-600">{activity.title}</h2>
                    <span className="text-sm text-gray-500">{activity.miles} miles</span>
                  </div>
                  <div className="flex items-center text-gray-500 mb-1">
                    <MapPin className="h-4 w-4 mr-1 text-indigo-400" />
                    <span className="text-sm">{activity.location}</span>
                  </div>
                  <div className="flex items-center text-gray-500 mb-2">
                    <Calendar className="h-4 w-4 mr-1 text-indigo-400" />
                    <span className="text-sm">{new Date(activity.date).toLocaleDateString()}, {activity.time}</span>
                  </div>
                  <button 
                    className="w-full bg-indigo-500 text-white rounded-lg py-2 text-sm hover:bg-indigo-600 transition-colors" 
                    onClick={() => {
                      setSelectedActivity(activity)
                      setCurrentScreen('ActivityDetails')
                    }}
                  >
                    View Details
                  </button>
                </div>
              ))}
            </div>
          </>
        )
      case 'CreateActivity':
        return (
          <>
            <div className="flex items-center p-4 bg-white shadow">
              <button className="mr-4" onClick={() => setCurrentScreen('Home')}>
                <ArrowLeft className="h-6 w-6 text-indigo-500" />
              </button>
              <h1 className="text-xl font-semibold text-indigo-600">Create Activity</h1>
            </div>
            <div className="flex-1 overflow-auto p-4">
              <div className="bg-white rounded-lg shadow p-4 mb-4">
                <input
                  type="text"
                  placeholder="Activity Title"
                  className="w-full text-lg font-semibold mb-4 p-2 border-b border-gray-200 text-gray-700 focus:outline-none focus:border-indigo-500"
                />
                <div className="flex items-center mb-4">
                  <MapPin className="h-5 w-5 text-indigo-500 mr-2" />
                  <select className="w-full p-2 bg-gray-100 rounded-lg text-gray-700">
                    <option value="">Select Neighborhood</option>
                    {neighborhoods.map(neighborhood => (
                      <option key={neighborhood} value={neighborhood}>{neighborhood}</option>
                    ))}
                  </select>
                </div>
                <div className="flex items-center mb-4">
                  <Calendar className="h-5 w-5 text-indigo-500 mr-2" />
                  <input
                    type="date"
                    className="w-full bg-gray-100 p-2 rounded-lg text-gray-700"
                  />
                </div>
                <div className="flex items-center mb-4">
                  <Clock className="h-5 w-5 text-indigo-500 mr-2" />
                  <input
                    type="time"
                    className="w-full bg-gray-100 p-2 rounded-lg text-gray-700"
                  />
                </div>
                <div className="flex items-center mb-4">
                  <Hash className="h-5 w-5 text-indigo-500 mr-2" />
                  <input
                    type="number"
                    placeholder="Miles"
                    min="0"
                    max="999"
                    className="w-full bg-gray-100 p-2 rounded-lg text-gray-700"
                  />
                </div>
                <textarea
                  placeholder="Description"
                  className="w-full h-24 p-2 bg-gray-100 rounded-lg mb-4 text-gray-700"
                ></textarea>
              </div>
            </div>
            <div className="p-4 bg-white shadow">
              <button 
                className="w-full bg-indigo-500 text-white rounded-lg py-3 font-semibold hover:bg-indigo-600 transition-colors" 
                onClick={() => {
                  handleCreateActivity({
                    title: "New Activity",
                    location: "Marina",
                    date: "2023-06-20",
                    time: "10:00 AM",
                    miles: 5,
                    description: "A new activity created by the user."
                  })
                  setCurrentScreen('Home')
                }}
              >
                Create Activity
              </button>
            </div>
          </>
        )
      case 'ActivityDetails':
        return (
          <>
            <div className="flex items-center p-4 bg-white shadow">
              <button className="mr-4" onClick={() => setCurrentScreen('Home')}>
                <ArrowLeft className="h-6 w-6 text-indigo-500" />
              </button>
              <h1 className="text-xl font-semibold text-indigo-600">Activity Details</h1>
            </div>
            <div className="flex-1 overflow-auto">
              <div className="bg-white p-4">
                <h2 className="text-2xl font-bold mb-2 text-indigo-600">{selectedActivity.title}</h2>
                <div className="flex items-center text-gray-500 mb-2">
                  <MapPin className="h-4 w-4 mr-1 text-indigo-400" />
                  <span className="text-sm">{selectedActivity.location}</span>
                </div>
                <div className="flex items-center text-gray-500 mb-2">
                  <Calendar className="h-4 w-4 mr-1 text-indigo-400" />
                  <span className="text-sm">{new Date(selectedActivity.date).toLocaleDateString()}</span>
                </div>
                <div className="flex items-center text-gray-500 mb-2">
                  <Clock className="h-4 w-4 mr-1 text-indigo-400" />
                  <span className="text-sm">{selectedActivity.time}</span>
                </div>
                <div className="flex items-center text-gray-500 mb-4">
                  <Hash className="h-4 w-4 mr-1 text-indigo-400" />
                  <span className="text-sm">{selectedActivity.miles} miles</span>
                </div>
                <p className="text-gray-600 mb-4">{selectedActivity.description}</p>
                {new Date(selectedActivity.date) > new Date() ? (
                  rsvpConfirmed[selectedActivity.id] ? (
                    <div className="flex items-center justify-between mb-4">
                      <span className="text-green-500 font-semibold">You're going!</span>
                      <button 
                        className="bg-red-500 text-white rounded-lg px-4 py-2 hover:bg-red-600 transition-colors" 
                        onClick={() => handleRSVP(selectedActivity.id)}
                      >
                        Cancel RSVP
                      </button>
                    </div>
                  ) : (
                    <button 
                      className="w-full bg-indigo-500 text-white rounded-lg py-2 mb-4 hover:bg-indigo-600 transition-colors" 
                      onClick={() => handleRSVP(selectedActivity.id)}
                    >
                      RSVP
                    </button>
                  )
                ) : (
                  <div className="bg-yellow-100 border border-yellow-400 text-yellow-700 px-4 py-3 rounded relative mb-4" role="alert">
                    <strong className="font-bold">Past Event</strong>
                    <span className="block sm:inline"> This event has already occurred.</span>
                  </div>
                )}
                {selectedActivity.creator === profile.name && (
                  <div className="flex justify-between mb-4">
                    <button 
                      className="bg-indigo-500 text-white rounded-lg px-4 py-2 hover:bg-indigo-600 transition-colors" 
                      onClick={() => {
                        // Implement edit logic
                      }}
                    >
                      Edit Activity
                    </button>
                    <button 
                      className="bg-red-500 text-white rounded-lg px-4 py-2 hover:bg-red-600 transition-colors" 
                      onClick={() => handleDeleteActivity(selectedActivity.id)}
                    >
                      Delete Activity
                    </button>
                  </div>
                )}
              </div>
              <div className="bg-white p-4 mt-4">
                <h3 className="text-lg font-semibold mb-2 text-indigo-600">Attendees</h3>
                <div className="flex flex-wrap gap-2 mb-4">
                  {Object.keys(rsvpConfirmed).filter(id => rsvpConfirmed[id] && parseInt(id) === selectedActivity.id).map((id) => (
                    <div key={id} className="flex items-center bg-gray-100 rounded-full px-3 py-1">
                      <div className="w-6 h-6 rounded-full bg-indigo-200 mr-2"></div>
                      <span className="text-sm text-gray-700">John Doe</span>
                    </div>
                  ))}
                </div>
                <h3 className="text-lg font-semibold mb-2 text-indigo-600">Comments</h3>
                <div className="space-y-4">
                  {(comments[selectedActivity.id] || []).map((comment) => (
                    <div key={comment.id} className="flex items-start space-x-3">
                      <div className="w-8 h-8 rounded-full bg-indigo-200"></div>
                      <div className="flex-grow">
                        <p className="font-semibold text-indigo-600">{comment.user}</p>
                        <p className="text-sm text-gray-500">{comment.text}</p>
                      </div>
                      {comment.user === profile.name && (
                        <button 
                          onClick={() => handleDeleteComment(selectedActivity.id, comment.id)}
                          className="text-red-500 hover:text-red-700"
                        >
                          <Trash2 className="h-4 w-4" />
                        </button>
                      )}
                    </div>
                  ))}
                </div>
                <div className="mt-4 flex items-center">
                  <input
                    type="text"
                    placeholder="Add a comment..."
                    className="flex-grow p-2 rounded-lg border border-gray-300 mr-2 text-gray-700 focus:outline-none focus:border-indigo-500"
                  />
                  <button 
                    className="p-2 rounded-full bg-indigo-500 text-white hover:bg-indigo-600 transition-colors"
                    onClick={() => handleAddComment(selectedActivity.id, "New comment")}
                  >
                    <MessageCircle className="h-5 w-5" />
                  </button>
                </div>
              </div>
            </div>
          </>
        )
      case 'Profile':
        return (
          <>
            <div className="flex items-center justify-between p-4 bg-white shadow">
              <button onClick={() => setCurrentScreen('Home')}>
                <ArrowLeft className="h-6 w-6 text-indigo-500" />
              </button>
              <h1 className="text-xl font-semibold text-indigo-600">Profile</h1>
              <button onClick={() => setEditingProfile(!editingProfile)}>
                {editingProfile ? <Check className="h-6 w-6 text-indigo-500" /> : <Edit2 className="h-6 w-6 text-indigo-500" />}
              </button>
            </div>
            <div className="flex-1 overflow-auto p-4">
              <div className="bg-white rounded-lg shadow p-4 mb-4">
                <div className="flex items-center mb-4">
                  <div className="w-20 h-20 rounded-full bg-indigo-200 mr-4 relative overflow-hidden">
                    {profile.image ? (
                      <img src={profile.image} alt="Profile" className="w-full h-full object-cover" />
                    ) : null}
                    {editingProfile && (
                      <label htmlFor="profile-image-upload" className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 cursor-pointer">
                        <Camera className="h-8 w-8 text-white" />
                        <input
                          id="profile-image-upload"
                          type="file"
                          accept="image/*"
                          className="hidden"
                          onChange={handleImageUpload}
                        />
                      </label>
                    )}
                  </div>
                  <div className="flex-grow">
                    {editingProfile ? (
                      <input
                        type="text"
                        name="name"
                        value={profile.name}
                        onChange={handleProfileChange}
                        className="text-xl font-semibold mb-1 p-2 bg-gray-100 rounded-lg text-gray-700 focus:outline-none focus:border-indigo-500"
                      />
                    ) : (
                      <h2 className="text-xl font-semibold text-indigo-600">{profile.name}</h2>
                    )}
                    {editingProfile ? (
                      <select
                        name="location"
                        value={profile.location}
                        onChange={handleProfileChange}
                        className="p-2 bg-gray-100 rounded-lg text-gray-700 focus:outline-none focus:border-indigo-500"
                      >
                        {neighborhoods.map(neighborhood => (
                          <option key={neighborhood} value={neighborhood}>{neighborhood}</option>
                        ))}
                      </select>
                    ) : (
                      <p className="text-gray-500">{profile.location}</p>
                    )}
                  </div>
                </div>
                {editingProfile && (
                  <button 
                    className="w-full border border-indigo-500 text-indigo-500 rounded-lg py-2 mb-4 hover:bg-indigo-50 transition-colors"
                    onClick={handleSaveProfile}
                  >
                    Save Profile
                  </button>
                )}
                <div className="space-y-2">
                  <div className="flex justify-between items-center">
                    <p className="text-gray-600"><strong>Preferred Distance:</strong></p>
                    {editingProfile ? (
                      <input
                        type="text"
                        name="preferredDistance"
                        value={profile.preferredDistance}
                        onChange={handleProfileChange}
                        className="p-2 bg-gray-100 rounded-lg text-gray-700 focus:outline-none focus:border-indigo-500"
                      />
                    ) : (
                      <p className="text-gray-600">{profile.preferredDistance}</p>
                    )}
                  </div>
                  <div className="flex justify-between items-center">
                    <p className="text-gray-600"><strong>Avg. Pace:</strong></p>
                    {editingProfile ? (
                      <input
                        type="text"
                        name="avgPace"
                        value={profile.avgPace}
                        onChange={handleProfileChange}
                        className="p-2 bg-gray-100 rounded-lg text-gray-700 focus:outline-none focus:border-indigo-500"
                      />
                    ) : (
                      <p className="text-gray-600">{profile.avgPace} min/mile</p>
                    )}
                  </div>
                  <div className="flex justify-between items-center">
                    <p className="text-gray-600"><strong>Favorite Terrain:</strong></p>
                    {editingProfile ? (
                      <input
                        type="text"
                        name="favoriteTerrain"
                        value={profile.favoriteTerrain}
                        onChange={handleProfileChange}
                        className="p-2 bg-gray-100 rounded-lg text-gray-700 focus:outline-none focus:border-indigo-500"
                      />
                    ) : (
                      <p className="text-gray-600">{profile.favoriteTerrain}</p>
                    )}
                  </div>
                </div>
              </div>
              <div className="bg-white rounded-lg shadow p-4">
                <div className="flex mb-4">
                  <button
                    className={`flex-1 py-2 ${activeTab === 'Upcoming' ? 'bg-indigo-500 text-white' : 'bg-gray-200 text-gray-700'} rounded-l-lg`}
                    onClick={() => setActiveTab('Upcoming')}
                  >
                    Upcoming
                  </button>
                  <button
                    className={`flex-1 py-2 ${activeTab === 'Past' ? 'bg-indigo-500 text-white' : 'bg-gray-200 text-gray-700'}`}
                    onClick={() => setActiveTab('Past')}
                  >
                    Past
                  </button>
                  <button
                    className={`flex-1 py-2 ${activeTab === 'MyActivities' ? 'bg-indigo-500 text-white' : 'bg-gray-200 text-gray-700'} rounded-r-lg`}
                    onClick={() => setActiveTab('MyActivities')}
                  >
                    My Activities
                  </button>
                </div>
                {activeTab === 'Upcoming' && (
                  <div>
                    <h3 className="text-lg font-semibold mb-2 text-indigo-600">Upcoming Activities</h3>
                    {activities.filter(activity => new Date(activity.date) > new Date() && rsvpConfirmed[activity.id]).map((activity) => (
                      <div 
                        key={activity.id} 
                        className="border-b border-gray-200 py-2 last:border-b-0 cursor-pointer hover:bg-gray-50"
                        onClick={() => {
                          setSelectedActivity(activity)
                          setCurrentScreen('ActivityDetails')
                        }}
                      >
                        <h4 className="font-semibold text-indigo-600">{activity.title}</h4>
                        <p className="text-sm text-gray-500">{activity.location}, {new Date(activity.date).toLocaleDateString()}, {activity.time}</p>
                      </div>
                    ))}
                  </div>
                )}
                {activeTab === 'Past' && (
                  <div>
                    <h3 className="text-lg font-semibold mb-2 text-indigo-600">Past Activities</h3>
                    {activities.filter(activity => new Date(activity.date) <= new Date() && rsvpConfirmed[activity.id]).map((activity) => (
                      <div 
                        key={activity.id} 
                        className="border-b border-gray-200 py-2 last:border-b-0 cursor-pointer hover:bg-gray-50"
                        onClick={() => {
                          setSelectedActivity(activity)
                          setCurrentScreen('ActivityDetails')
                        }}
                      >
                        <h4 className="font-semibold text-indigo-600">{activity.title}</h4>
                        <p className="text-sm text-gray-500">{activity.location}, {new Date(activity.date).toLocaleDateString()}, {activity.time}</p>
                      </div>
                    ))}
                  </div>
                )}
                {activeTab === 'MyActivities' && (
                  <div>
                    <h3 className="text-lg font-semibold mb-2 text-indigo-600">My Created Activities</h3>
                    {activities.filter(activity => activity.creator === profile.name).map((activity) => (
                      <div 
                        key={activity.id} 
                        className="border-b border-gray-200 py-2 last:border-b-0 cursor-pointer hover:bg-gray-50"
                        onClick={() => {
                          setSelectedActivity(activity)
                          setCurrentScreen('ActivityDetails')
                        }}
                      >
                        <h4 className="font-semibold text-indigo-600">{activity.title}</h4>
                        <p className="text-sm text-gray-500">{activity.location}, {new Date(activity.date).toLocaleDateString()}, {activity.time}</p>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
          </>
        )
      case 'Notifications':
        return (
          <>
            <div className="flex items-center p-4 bg-white shadow">
              <button className="mr-4" onClick={() => setCurrentScreen('Home')}>
                <ArrowLeft className="h-6 w-6 text-indigo-500" />
              </button>
              <h1 className="text-xl font-semibold text-indigo-600">Notifications</h1>
            </div>
            <div className="flex-1 overflow-auto p-4">
              {notifications.map(notification => (
                <div 
                  key={notification.id} 
                  className={`bg-white rounded-lg shadow mb-4 p-3 ${notification.read ? 'opacity-50' : ''}`}
                  onClick={() => handleNotificationClick(notification.id)}
                >
                  <p className="text-gray-700">{notification.message}</p>
                </div>
              ))}
            </div>
          </>
        )
      default:
        return null
    }
  }

  return (
    <div className="flex flex-col h-screen bg-gray-100 mx-auto border border-gray-300" style={{ minWidth: '375px', maxWidth: '480px' }}>
      {renderScreen()}
      <div className="flex justify-between items-center p-4 bg-white shadow relative">
        <button className={`${currentScreen === 'Home' ? 'text-indigo-500' : 'text-gray-500'}`} onClick={() => setCurrentScreen('Home')}>
          <Home className="h-6 w-6" />
        </button>
        <button
          className="bg-indigo-500 text-white p-3 rounded-full shadow-lg hover:bg-indigo-600 transition-all duration-200 absolute left-1/2 -translate-x-1/2 -top-6"
          onClick={() => setCurrentScreen('CreateActivity')}
        >
          <Plus className="h-6 w-6" />
        </button>
        <button className={`${currentScreen === 'Profile' ? 'text-indigo-500' : 'text-gray-500'}`} onClick={() => setCurrentScreen('Profile')}>
          <User className="h-6 w-6" />
        </button>
      </div>
    </div>
  )
}

export default App;